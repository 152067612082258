import React from "react";
import call from "../../api";
import Icon from "../Icon/Icon";
import Fuse from "fuse.js";

export default class DropdownSelectField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: this.props.value ? Array.isArray(this.props.value) ? this.props.value : [this.props.value] : [],
            items: null,
            search: ""
        }
    }

    componentDidMount() {
        call(
            "GET",
            `generic/${this.props.type}`,
            (status, content) => {
                if (status !== 200) {
                    alert(`Failed to list ${this.props.type}s: ${content.message}`);
                    return;
                }

                let items = Object.values(content);
                this.setState({"items": items});
            }
        );
    }

    isSelected(item) {
        for (const s in this.state.selected)
            if (item.path === this.state.selected[s].path)
                return true;

        return false;
    }

    onSearch(e) {
        e.stopPropagation();
        this.setState({"search": e.target.value});
    }

    SEARCH_FIELDS = ["name"];

    search() {
        if (!this.state.search)
            return this.state.items.sort((a, b) => a.name.localeCompare(b.name));

        const options = {
            includeScore: false,
            shouldSort: true,
            keys: this.SEARCH_FIELDS,
            threshold: 0.8
        };
        const fuse = new Fuse(this.state.items, options);
        const results = fuse.search(this.state.search);

        const content = [];
        for (const key in results) {
            content.push(results[key].item);
        }
        return content;
    }

    renderItem(item, selected) {
        const region = this.props.displayRegion ? <span className="item-info">{item.region}</span> : null;
        console.log(region);

        if (selected)
            return (
                <hstack className="selected-item" spacing="xxs">
                    <span className="item-name">{item.name || item.path || item}</span>
                    {region}
                    <span className="item-close" onClick={() => this.deselectItem(item)}>╳</span>
                </hstack>
            );

        return (
            <hstack className="unselected-item" onClick={() => this.selectItem(item)} spacing="xs">
                <span className="item-info">{item.name || item.path || item}</span>
                {region}
            </hstack>
        );
    }

    render() {
        let select_ordered;
        try {
            select_ordered = this.state.selected.sort((a, b) => (a.name || a.path || a).localeCompare(b.name || b.path || b));
        } catch {
            select_ordered = this.state.selected;
        }

        const selected = [];
        for (const i in select_ordered)
            selected.push(this.renderItem(select_ordered[i], true));

        if (this.state.items === null) {  // Loading search
            return (
                <vstack className="field-group select" spacing="xs">
                    <hstack className="select" spacing="s">
                        <span
                            className="field-label">{this.props.displayName ? this.props.displayName : this.props.name}</span>
                        <hstack className="selected-container" stretch="">
                            {selected}
                            <span style={{fontWeight: 300}}>Loading search...</span>
                            <div className="break"/>
                        </hstack>
                    </hstack>
                </vstack>
            )
        }

        const ordered = this.search();
        const items = [];
        for (const i in ordered) {
            const item = ordered[i];
            if (!this.isSelected(item))
                items.push(this.renderItem(item, false))
        }

        const valid = this.props.validation(this.valuesToSubmit(this.state.selected));

        return (
            <hstack className={`select ${valid ? 'invalid' : ''}`} spacing="s">
                <span className="field-label">{this.props.displayName ? this.props.displayName : this.props.name}</span>
                <hstack className="selected-container" stretch="">
                    {selected}
                    <hstack className="select-search">
                        <Icon icon="search" className="search-icon" width={16} height={16}/>
                        <input type="text"
                               className="select-search"
                               placeholder="Search..."
                               value={this.state.search}
                               onChange={(e) => this.onSearch(e)}
                        />
                        <span className="search-close" onClick={() => this.setState({"search": ""})}>╳</span>
                    </hstack>
                    <div className="break"/>
                    {items}
                    <div className="break" style={{display: valid ? null : "none"}}/>
                    <span className="validation" style={{display: valid ? null : "none"}}>{valid}</span>
                </hstack>
            </hstack>
        )
    }

    valuesToSubmit(values) {
        const db_vals = [];
        for (const i in values)
            db_vals.push(values[i].path);
        return db_vals;
    }

    submitValues(values) {
        this.props.onChange(this.valuesToSubmit(values));
    }

    deselectItem(sel) {
        const minItems = this.props.minItems ? this.props.minItems : -Infinity;
        if (this.state.selected.length <= minItems) {
            this.props.sendNotification("warning", "Lower item count limit reached",
                `You must have at lest ${minItems} ${this.props.displayName ? this.props.displayName : this.props.name} selected.`,
                3
            );
            return;
        }

        const selected_cpy = JSON.parse(JSON.stringify(this.state.selected));
        const new_selected = selected_cpy.filter((val) => {
            return val.path !== sel.path
        });
        this.setState({selected: new_selected});
        this.submitValues(new_selected)
    }

    getMaxItems() {
        return this.props.maxItems ? this.props.maxItems : Infinity;
    }

    selectItem(item) {
        if (this.state.selected.length >= this.getMaxItems()) {
            this.props.sendNotification("warning", "Upper item count limit reached",
                `You can only select up to ${this.getMaxItems()} ${this.props.displayName ? this.props.displayName : this.props.name}.`,
                3
            );
            return;
        }

        const selected_cpy = JSON.parse(JSON.stringify(this.state.selected));
        selected_cpy.push(item);
        this.setState({selected: selected_cpy});
        this.submitValues(selected_cpy)
    }
}


export class ResourceSelectField extends DropdownSelectField {
    renderItem(item, selected) {
        if (selected)
            return (
                <hstack className="selected-item" spacing="xxs">
                    <span className="item-name">{item.name || item.path || item}</span>
                    <span className="item-info">{item.vpc.name}</span>
                    <span className="item-info">{item.vpc.region}</span>
                    <span className="item-close" onClick={() => this.deselectItem(item)}>╳</span>
                </hstack>
            );

        return (
            <hstack className="unselected-item" onClick={() => this.selectItem(item)} spacing="xxs">
                <span className="item-name">{item.name || item.path || item}</span>
                <span className="item-info">{item.vpc.name}</span>
                <span className="item-info">{item.vpc.region}</span>
            </hstack>
        );
    }
}


export class SingleItemDropdownField extends DropdownSelectField {
    // constructor(props) { // why do we need this?
    //     super(props);
    //     this.state = {
    //         selected: [this.props.value],
    //         items: null,
    //         search: ""
    //     }
    // }

    valuesToSubmit(values) {
        return values.length ? values[0].path : null;
    }

    getMaxItems() {
        return 1;
    }
}


export class UserSelectField extends SingleItemDropdownField {
    componentDidMount() {
        call(
            "GET",
            `generic/user-ad`,
            (status, content) => {
                if (status !== 200) {
                    alert(`Failed to list users: ${content.message}`);
                    return;
                }

                call(
                    "GET",
                    "generic/user-pool",
                    (status2, content2) => {
                        if (status2 !== 200) {
                            alert(`Failed to list temporary users: ${content2.message}`);
                            return;
                        }

                        this.setState({"items": Object.values(content).concat(Object.values(content2))});
                    }
                )
            }
        );
    }

    renderItem(item, selected) {
        let icon = item.type === "user-pool" ? "user-check" : "user";

        if (selected)
            return (
                <hstack className="selected-item" key={item.path} spacing="xxs">
                    <span className="item-icon"><Icon icon={icon} height={16} width={16}/></span>
                    <span className="item-name">{item.username || item.email}</span>
                    <span className="item-close" onClick={() => this.deselectItem(item)}>╳</span>
                </hstack>
            );

        return (
            <hstack className="unselected-item"
                    onClick={() => this.selectItem(item)}
                    key={item.path}
                    spacing="xxs">
                <span className="item-icon"><Icon icon={icon} height={16} width={16}/></span>
                <span className="item-name">{item.username || item.email}</span>
            </hstack>
        );
    }
}


export class PermissionSelectField extends DropdownSelectField {
    STATIC = [
        {
            "name": "Admin",
            "path": "_internal.permission.*.**"
        },
        {
            "name": "Superuser",
            "path": "**"
        },
        {
            "name": "Break Glass",
            "path": "_internal.permission.break_glass"
        }
    ]

    SEARCH_FIELDS = ["name", "vpc.account.name", "account.name"];

    permsToItems(available, selected) {
        const result = [];
        for (const i in selected) {
            const perm = selected[i];
            const i_avail = available.findIndex(avail => {
                return avail.path === perm
            });
            if (i_avail === -1)
                result.push({
                    "name": perm,
                    "path": perm
                })
            else
                result.push(available[i_avail]);
        }

        return result;
    }

    componentDidMount() {
        call(
            "GET",
            `generic/bastion_cfg`,
            (bastionStatus, bastionContent) => {
                if (bastionStatus !== 200) {
                    alert(`Failed to list bastion apps: ${bastionContent.message}`);
                    return;
                }

                call(
                    "GET",
                    "generic/console_cfg",
                    (consoleStatus, consoleContent) => {
                        if (consoleStatus !== 200) {
                            alert(`Failed to list console apps: ${consoleContent.message}`);
                            return;
                        }

                        call(
                            "GET",
                            "generic/appstream_cfg",
                            (appStreamStatus, appStreamContent) => {
                                if (appStreamStatus !== 200) {
                                    alert(`Failed to list appstream apps: ${appStreamContent.message}`);
                                    return;
                                }

                                const configs = Object.values(bastionContent)
                                    .concat(Object.values(consoleContent))
                                    .concat(Object.values(appStreamContent));

                                const items = configs.concat(this.STATIC);
                                this.setState({"items": items, "selected": this.permsToItems(items, this.state.selected)});
                            }
                        );
                    }
                )
            }
        );
    }

    renderItem(item, selected) {
        const appIcons = {
            "bastion_cfg": "cpu",
            "console_cfg": "box",
            "appstream_cfg": "monitor"
        }
        const icon = appIcons[item.type] || "user";

        let info = null;
        if (item.vpc != null) {
            info = item.vpc.account.name ? item.vpc.account.name : item.vpc.account.path;
        } else if (item.account != null) {
            info = item.account.name ? item.account.name : item.account.path;
        }
        console.log(info);

        const infoStyle = {display: info != null ? null : "none"}

        if (selected)
            return (
                <hstack className="selected-item" key={item.path} spacing="xxs">
                    <span className="item-icon"><Icon icon={icon} height={16} width={16}/></span>
                    <span className="item-name">{item.name ? item.name : item.path}</span>
                    <span className="item-info" style={infoStyle}>{info}</span>
                    <span className="item-close" onClick={() => this.deselectItem(item)}>╳</span>
                </hstack>
            );

        return (
            <hstack className="unselected-item"
                    onClick={() => this.selectItem(item)}
                    spacing="xxs"
                // style={{display: this.isOpen() ? null : "none"}}
                    key={item.path}>
                <span className="item-icon"><Icon icon={icon} height={16} width={16}/></span>
                <span className="item-name">{item.name ? item.name : item.path}</span>
                <span className="item-info" style={infoStyle}>{info}</span>
            </hstack>
        );
    }

    valuesToSubmit(values) {
        const db_vals = [];
        for (const i in values)
            db_vals.push(values[i].path);
        return db_vals;
    }
}


export class AppSelectField extends PermissionSelectField {
    STATIC = [];
}


export class ResourceTypeSelect extends SingleItemDropdownField {
    ITEMS = [
        {
            "name": "EC2 Instance",
            "path": "ec2:instance"
        },
        {
            "name": "RDS Instance",
            "path": "rds:db"
        },
        {
            "name": "RDS Cluster",
            "path": "rds:cluster"
        },
        {
            "name": "EKS Cluster",
            "path": "eks:cluster"
        }
    ]

    constructor(props) {
        super(props);
        this.state = {
            selected: this.props.value ? [
                {
                    path: this.props.value,
                    name: this.ITEMS[this.ITEMS.findIndex((i) => {
                        return i.path === this.props.value
                    })].name
                }
            ] : [],
            items: this.ITEMS,
            search: ""
        }
    }

    componentDidMount() {
    }
}