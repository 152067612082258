import BaseEditItem from "./BaseEditItem";
import TextField, {TextArea} from "../Fields/TextField";
import "./EditItem.scss";


export default class PolicyEdit extends BaseEditItem {
    FIELDS = [
        {
            element: TextField,
            name: "name",
            // validate: (val) => {
            //     if (val.search(" ") !== -1)
            //         return "Can't use spaces."
            // }
        },
        {
            element: TextField,
            name: "description",
        },
        {
            element: TextArea,
            name: "policy",
            displayName: "Inline Policy",
            validate: (value) => {
                if (!value)
                    return "Can't be empty"

                try {
                    JSON.parse(value);
                } catch (e) {
                    return "Invalid JSON."
                }
            }
        },
        {
            element: TextField,
            name: "arn",
            displayName: "AWS Managed Policy ARN",
            validate: (value) => {
                if (!value)
                    return "Can't be empty";

                if (!value.startsWith("arn:aws:iam::aws:policy/"))
                    return "Must start with 'arn:aws:iam::aws:policy/'";
            }
        }
    ]

    validate(field, value) {
        if (field.name === "policy" || field.name === "arn")
            if (this.state.item.policy && this.state.item.arn)
                return "A policy can not have a managed ARN and an inline policy at the same time."

        if (this.state.item.arn && field.name === "policy")
            return;

        if (this.state.item.policy && field.name === "arn")
            return;

        return super.validate(field, value);
    }
}
